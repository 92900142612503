import React, {useState} from 'react';
import IconCheckChecked from "../assets/icons/IconCheckChecked";
import IconCheckUnchecked from "../assets/icons/IconCheckUnchecked";

const HiddenSelect = ({value, setValue, text, hide,authed, defaultValue = false}) => {
    const [isChecked, setIsChecked] = useState(defaultValue)

    return (
        <label className={`nice_checkbox form  ${hide && "hide"}`}>
            <input type="checkbox" checked={isChecked} onChange={(e) => {
                setIsChecked(e.target.checked)
            }}/>
            <div className="fake_checkbox">
                {isChecked ?
                    <IconCheckChecked/>
                    :
                    <IconCheckUnchecked/>
                }
            </div>
            {text}
            <select className={`${isChecked&&"show"}`} onChange={(e) => {
                setValue(e.target.value)
            }} defaultValue={value}>
                <option value="3">
                    1 hour
                </option>
                <option value="1">
                    1 day
                </option>
                <option value="2" selected>
                    1 week
                </option>
                <option value="4">
                    1 month
                </option>
                <option value="6">
                    1 year
                </option>
                {authed&&
                    <option value="7">
                        5 year
                    </option>
                }
            </select>
        </label>
    );
};

export default HiddenSelect;