import React from 'react';
import IconCancel from "../assets/icons/IconCancel";
import IconComplete from "../assets/icons/IconComplete";
import IconError from "../assets/icons/IconError";

const FileList = ({files, handleRemoveFile, retryUpload, cancelable = true}) => {
    return (
        <div className="file_holder">
            {files.map(({name, progress, finished, error, file}, index) =>(
                <div className="file" key={index+name}>
                    <div className="num">Image #{index}</div>
                    <div className="name">
                        {file.name}

                        {
                            cancelable ?
                                <IconCancel className="action" onClick={() => handleRemoveFile(index)}/>
                                :
                                finished ?
                                    <IconComplete className="action"/>
                                    :
                                    error &&
                                    <>
                                        <IconError className="action"/>
                                    </>
                        }
                    </div>
                    <div className="progressbar">
                        <div className="progress" style={{width:progress+"%"}}/>
                    </div>
                    {error &&
                        <div className="suggestion">
                            <p>Upload failed</p>

                            <div className="row">
                                <div className="col c-50 l">
                                    <button className="btn green" onClick={() => {
                                        retryUpload(index)
                                    }}>Try again
                                    </button>
                                </div>
                                <div className="col c-50 r">
                                    <button className="btn red" onClick={() => {
                                        handleRemoveFile(index)
                                    }}>Remove
                                    </button>
                                </div>
                                <div className="cl"></div>
                            </div>
                        </div>
                    }
                </div>
            ) )}
        </div>
    );
};

export default FileList;