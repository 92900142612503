import React from 'react';

const IconDescription = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clip-path="url(#clip0_2115_572)">
                <path d="M22 3H2V18H6.5V20.5L11.5 18H22V3Z" fill="#525EC9" stroke="#525EC9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7 9.75V11.25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 9.75V11.25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M17 9.75V11.25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_2115_572">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default IconDescription;