import React, {useState} from 'react';
import IconCheckUnchecked from "../assets/icons/IconCheckUnchecked";
import IconCheckChecked from "../assets/icons/IconCheckChecked";

const HiddenInput = ({value, setValue, placeholder, text, hide, defaultValue = false}) => {

    const [isChecked, setIsChecked] = useState(defaultValue)

    return (
        <label className={`nice_checkbox form  ${hide && "hide"}`}>
            <input type="checkbox" checked={isChecked} onChange={(e) => {
                setIsChecked(e.target.checked)
            }}/>
            <div className="fake_checkbox">
                {isChecked ?
                    <IconCheckChecked/>
                    :
                    <IconCheckUnchecked/>
                }
            </div>
            {text}
            <input type="password" className={`${isChecked&&"show"}`} placeholder={placeholder} value={value} autoComplete="off" onChange={(e) => setValue(e.target.value)}/>
        </label>
    );
};

export default HiddenInput;