import React, {useRef, useState} from 'react';
import Title from "../components/Title";
import {useLocation, useNavigate} from "react-router-dom";
import Link from "../components/Link";
import Button from "../components/Button";


const Links = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [onePost] = useState(location.state.onePost)
    const [collage] = useState(location.state.isCollage)
    const [files] = useState(location.state.files);
    const [previewLink] = useState(location.state.previewLink);

    const ref = useRef();

    const copy =async (txt) => {
        if ("clipboard" in navigator) {
            await navigator.clipboard.writeText(txt);
        } else {
            document.execCommand("copy", true, txt);
        }
    }
    return (
        <>
            <div className="done_holder">
                <Title title={"Successfuly uploaded!"}/>
                {onePost ?
                    <>
                        <Link link={(previewLink) + "p/" + location.state.pack}/>
                        <Button text={"Copy"} handleClick={() => {copy((previewLink) + "p/" + location.state.pack)}}/>
                    </>                    :
                    files &&
                    <>
                        <div className={"link"} ref={ref}>
                            {
                                files.map((item, index) => (
                                    <p key={index}>{(previewLink) + "i/" + item.name+"\n"}</p>
                                ))
                            }
                        </div>
                        <Button text={"Copy"} handleClick={(e) => {copy(ref.current.textContent)}}/>
                    </>
                }


                <Title title={"Link for delete"}/>
                <Link link={(previewLink) + "d/" + location.state.deleteLink}/>
            </div>


            <div className="fixed">
                <div className="inner">
                    <Button text={"Back to home"} handleClick={() => {
                        navigate("/")
                    }}/>
                </div>
            </div>

        </>
    );
};

export default Links;