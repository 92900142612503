import React from 'react';

const Button = ({text, handleClick, disabled = false , primary = false}) => {
    return (
        <button
            className={`btn ${primary && "blue"}`}
            onClick={() => {handleClick()}}
            disabled={disabled}
        >{text}</button>
    );
};

export default Button;