import React from 'react';

const DropFile = ({handleFiles, hide, accept}) => {
    return (
        <div
            className={`filebox ${hide && "hide"}`}
            onDragOver={(e) => {
                e.preventDefault();
            }}
            onDragLeave={(e) => {
                e.preventDefault();
            }}
            onDrop={(e) => {
                e.preventDefault();
                handleFiles(e.dataTransfer.files)
            }}
        >
            <div className="filebox-info">
                <b>Drop your files here</b>
                <label style={{cursor: "pointer"}}>
                    <input accept={accept.join(", ")} multiple type="file"
                           onChange={(e) => {
                               handleFiles(e.target.files)
                           }}/>Browse Files
                </label> from your computer
            </div>
        </div>
    );
};

export default DropFile;