import React from 'react';

const ButtonBrowse = ({text, accept, handleFiles}) => {
    return (

        <label className="btn blue">
            <input accept={accept.join(", ")} multiple type="file"
                   onChange={(e) => handleFiles(e.target.files)}/>
            {text}
        </label>

    )
        ;
};

export default ButtonBrowse;