import React from 'react';
import IconCheckChecked from "../assets/icons/IconCheckChecked";
import IconCheckUnchecked from "../assets/icons/IconCheckUnchecked";

const CheckBox = ({value, setValue, text, hide, disabled = false}) => {
    return (
        <label className={`nice_checkbox ${hide && "hide"} ${disabled && "disabled"}`}>
            <input type="checkbox" defaultChecked={value} disabled={disabled} onChange={(e) => {
                setValue(e.target.checked)
            }}/>
            <div className="fake_checkbox">
                {value ?
                    <IconCheckChecked/>
                    :
                    <IconCheckUnchecked/>
                }
            </div>
            {text}
        </label>
    );
};

export default CheckBox;