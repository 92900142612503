import React from 'react';

const Link = ({link}) => {
    return (
        <div
            className="link">{link}
        </div>

    );
};

export default Link;